exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-thank-you-tsx": () => import("./../../../src/pages/contact-us-thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-us-thank-you-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-feature-70-language-support-tsx": () => import("./../../../src/pages/feature/70language-support.tsx" /* webpackChunkName: "component---src-pages-feature-70-language-support-tsx" */),
  "component---src-pages-feature-consent-log-tsx": () => import("./../../../src/pages/feature/consent-log.tsx" /* webpackChunkName: "component---src-pages-feature-consent-log-tsx" */),
  "component---src-pages-feature-cookie-banner-tsx": () => import("./../../../src/pages/feature/cookie-banner.tsx" /* webpackChunkName: "component---src-pages-feature-cookie-banner-tsx" */),
  "component---src-pages-feature-cookie-privacy-policy-tsx": () => import("./../../../src/pages/feature/cookie-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-feature-cookie-privacy-policy-tsx" */),
  "component---src-pages-feature-cookie-ssl-scanner-tsx": () => import("./../../../src/pages/feature/cookie-ssl-scanner.tsx" /* webpackChunkName: "component---src-pages-feature-cookie-ssl-scanner-tsx" */),
  "component---src-pages-feature-preference-center-tsx": () => import("./../../../src/pages/feature/preference-center.tsx" /* webpackChunkName: "component---src-pages-feature-preference-center-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */)
}

